<template>
  <div class='bg_box'>
    <div class="armip">
      <div class="pc" v-if="equipment == 'pc'">
        <navig :w="w" :only="only" />
        <img src="~img/Armip/01.png" class="pc_top_img" />
        <div class="pc_content">
          <div class="pc_template" v-for="(item, index) in list" :key="index">
            <div class="t_title">
              <div class="pc_title" v-html="item.productSeriesStr"></div>
              <div class="pc_fen" :style="'background:' + item.color"></div>
            </div>
            <ul class="pc_list">
              <li v-for="(val, index) in item.productInfos" :key="index">
                <div class="color_piece" :style="'background:' + item.color" v-if="item.isInterval == 0"></div>
                <h3 class="one-txt-cut" :class="{ cur: val.target }" @click="blank(val.target)">
                  {{ val.productName }}
                </h3>
                <img src="~img/Armip/02.png" class="jiantou" @click="blank(val.target)" v-if="val.target" />
                <p class="instructions " :class="val.productExt.length> 0 ? 'txt-cut_mor' : ''" v-html='val.productDesc'>
                  <!-- {{ val.productDesc }} -->
                </p>
                <div class="product_list">
                  <div class="p_list clearfix" v-for="(lian, index) in val.productExt" :key="index">
                    <p class="p1">——</p>
                    <p class="p2" :class="{ dixian: lian.fjDescTarget }" v-html='lian.fjDesc' @click="blank(lian.fjDescTarget)">
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <p class="shengming">
            声明：Arm，Cortex，DynamlQ，big.LITTLE，Neon，TrustZone，Neoverse，SecurCore，Mali，Immortalis，Ethos，CryptoCell，POP，Artisan，AMBA，CoreLink，CoreSight，Keil，Mbed，ULINK和Socrates是Arm
            Limited (或其子公司)的注册商标或商标。</p>
        </div>
      </div>
      <div class="web" v-if="equipment == 'web'">
        <web-nav />
        <img src="~img/Armip/03.png" class="web_top_img" />
        <div class="web_content">
          <div class="web_template" v-for="(item, index) in list" :key="index">
            <div class="web_title">
              <p v-html="item.productSeriesStr" class="one-txt-cut"></p>
              <div class="web_fen" :style="'background:' + item.color"></div>
            </div>
            <ul class="web_list">
              <li v-for="(val, index) in item.productInfos" :key="index">
                <div class="web_piece" :style="'background:' + item.color" v-if="item.isInterval == 0"></div>
                <h3 class="txt-cut" @click="blank(val.target)">
                  {{ val.productName }}
                </h3>
                <img src="~img/Armip/02.png" class="jiantou" @click="blank(val.target)" v-if="val.target" />
                <div class="web_fengex"></div>
                <p class="instructions" v-html='val.productDesc'>
                  <!-- {{ val.productDesc }} -->
                </p>
                <div class="product_list">
                  <div class="p_list clearfix" v-for="(lian, index) in val.productExt" :key="index">
                    <p class="p1">一</p>
                    <p class="p2" :class="{ dixian: lian.fjDescTarget }" v-html='lian.fjDesc'>
                      <!-- {{ lian.fjDesc }} -->
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <p class="shengming">
          声明：Arm，Cortex，DynamlQ，big.LITTLE，Neon，TrustZone，Neoverse，SecurCore，Mali，Immortalis，Ethos，CryptoCell，POP，Artisan，AMBA，CoreLink，CoreSight，Keil，Mbed，ULINK和Socrates是Arm
          Limited (或其子公司)的注册商标或商标。</p>
        <up />
        <div class="foot">
          <web-footer />
        </div>
      </div>
    </div>
    <pc-footer v-if="equipment == 'pc'" :showFooter="showFooter" />

  </div>

</template>



<script>
import $ from "jquery";
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: undefined,
      h: undefined,
      equipment: "",
      only: true,
      showFooter: true,
      list: [],
      pStatus: "",
      istitle: "", // 子产品
      level: "" // 产品大分类
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    this.h = document.documentElement.clientHeight; // 视口的高度
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.dataId = this.getQueryValue("dataId");
    this.pStatus = this.getQueryValue("pStatus");
    this.istitle = this.getQueryValue("istitle");
    if (this.pStatus == 2) {
      this.yuLan();
    } else {
      this.getArmIp();
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
    if (from.name == "brief") {
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
    }
  },
  mounted() {
    document.title = this.istitle + "-Arm IP-安谋科技";

    $("body").css("cursor", "default");
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  watch: {
    $route(to, form) {
      // console.log(to.query.dataId);
      this.dataId = to.query.dataId;
      this.getArmIp();
      this.istitle = this.getQueryValue("istitle");

      document.title = this.istitle + "-Arm IP-安谋科技";
    }
  },
  methods: {
    async getArmIp() {
      const res = await this.axios.get(
        `/v1/api/product_infos?dataId=${this.dataId}`
      );
      if (res.code == "00") {
        this.list = res.data;
        console.log(this.list)
        this.list.forEach(element => {
          element.productInfos.forEach(item => {
            item.productDesc = `<span>${item.productDesc}</span>`
            item.productExt.forEach(val => {
              val.fjDesc = `<span>${val.fjDesc}</span>`
            })
          })
        });
      } else {
        this.$message.error("数据请求失败");
      }
    },
    async yuLan() {
      const res = await this.axios.get(
        `/v1/api/product_infos?dataId=${this.dataId}&pStatus=${this.pStatus}`
      );
      if (res.code == "00") {
        this.list = res.data;
      } else {
        this.$message.error("数据请求失败");
      }
    },
    blank(target) {
      console.log(target);
      if (target) {
        window.open(target, "_blank");
      }
    }
  }
};
</script>

<style lang="less" >
.bg_box {
  background: #000;
}
.armip {
  background-color: #000;
  .pc {
    background: #000;
    position: relative;
    border-top: 1px solid;
    .pc_top_img {
      height: 10.8rem /* 1080/100 */;
      position: absolute;
      top: 0;
      left: 0;
    }
    .pc_content {
      padding-left: 0.8rem /* 133/100 */;
      position: relative;
      margin-top: 15%;
      .pc_template {
        .t_title {
          .pc_title {
            font-size: 80px;
            color: #ebebeb;
            font-weight: 400;
            sup {
              font-size: 30px;
            }
          }
          .pc_fen {
            width: 4.5rem /* 358/100 */;
            height: 4px;
            background: #fabf02;
            margin-top: 0.2rem /* 20/100 */;
          }
        }
        .pc_list {
          margin-top: 80px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            height: 440px;
            width: 357px;
            margin-right: 100px;
            margin-bottom: 100px;
            position: relative;
            border-bottom: 1px solid rgb(128, 127, 127);

            .color_piece {
              width: 36px;
              height: 36px;
              background: #fabf02;
              position: absolute;
              top: 0;
              left: 0;
            }
            h3 {
              position: relative;
              font-size: 30px;
              font-weight: 400;
              color: #ebebeb;
              line-height: 60px;
              border-bottom: 1px solid #fff;
              font-family: Arial;
              padding-right: 40px;
            }
            .cur {
              cursor: pointer;
            }
            .jiantou {
              width: 32px;
              position: absolute;
              top: 13px;
              right: 0;
              cursor: pointer;
            }
            .instructions {
              font-size: 16px;
              font-weight: 400;
              color: #ebebeb;
              line-height: 26px;
              font-family: Arial;
              margin-top: 39px;
              span {
                a {
                  color: #fff;
                  text-decoration: underline;
                }
              }
            }
            .product_list {
              margin-top: 38px;
              padding-left: 20px;
              height: 160px;
              overflow-y: auto;

              .p_list {
                margin-bottom: 20px /* 20/100 */;
                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ebebeb;
                  line-height: 20px;
                  font-family: Arial;
                }
                .p1 {
                  float: left;
                }
                .p2 {
                  margin-left: 45px;
                }
                .dixian {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }
            .product_list::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 10px /* 10/100 */; /*高宽分别对应横竖滚动条的尺寸*/
              height: 1px;
            }
            .product_list::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 10px;
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #535353;
            }
            .product_list::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              background: #ededed;
            }
          }
        }
      }
      .shengming {
        width: 80%;
        font-size: 0.16rem;
        color: #777;
        margin-bottom: 0.2rem;
      }
    }
  }
  .web {
    background: #000;
    position: relative;
    border-top: 1px solid;
    .web_top_img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .web_content {
      position: relative;
      margin: auto;
      margin-top: 113px;
      width: 86.45%;
      .web_template {
        margin-top: 79px;
        .web_title {
          p {
            font-size: 26px;
            font-weight: 400;
            font-family: Arial;
            color: #ebebeb;
          }
          .web_fen {
            width: 134px;
            height: 2px;
            background: #f1c142;
            margin-top: 10px;
          }
        }
        .web_list {
          padding-bottom: 17px;
          // border-bottom: 1px solid #cccccc;
          li {
            margin-top: 41.5px;
            position: relative;
            border-bottom: 1px solid #575656;

            .web_piece {
              width: 18px;
              height: 18px;
              background: #f1c142;
              position: absolute;
              top: 0;
              left: 0;
            }
            .jiantou {
              width: 17.5px;
              position: absolute;
              top: 3.5px;
              right: 0;
            }
            h3 {
              position: relative;
              font-size: 20px;
              color: #ebebeb;
              font-family: Arial;
              cursor: pointer;
            }
            .web_fengex {
              width: 100%;
              height: 1px;
              background: #fff;
              margin-top: 5px;
            }
            .instructions {
              font-size: 12px;
              font-family: Arial;
              margin-top: 19.5px;
              color: #ebebeb;
              span {
                a {
                  color: #fff;
                  text-decoration: underline;
                }
              }
            }
            .product_list {
              margin-left: 10px;
              margin-top: 19.5px;
              // margin-bottom: 17px;
              .p_list {
                margin-bottom: 10px;
                .p1 {
                  float: left;
                  color: #ebebeb;
                  font-size: 10px;
                  font-family: Arial;
                }
                .p2 {
                  margin-left: 19px;
                  color: #ebebeb;
                  font-size: 10px;
                  font-family: Arial;
                }
                .dixian {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .shengming {
      color: #777;
      margin: auto;
      margin-top: 20px;
    }
    .foot {
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>
